import React, { useEffect, useRef, useState } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import '../styles/characterGen.scss';
import initCharacterGenerator from '../components/CGJS/Character';
import { Button, Table } from 'react-bootstrap';

const CharacterGen = () => {
    const canvasRef = useRef(null);
    const [selectedImages, setSelectedImages] = useState([]);

    useEffect(() => {
        if (canvasRef.current) {
            initCharacterGenerator(canvasRef.current, setSelectedImages);
        }
    }, []);

    return (
        <>
            <NavBar />

            <div className="intro">
                <h1>Ice Cream Character Generator</h1>
                <p className="groupInst">A program by Liam Erickson</p>
                <hr className="desktopHr" />
            </div>


            <div className="canvasContainer">
                <canvas ref={canvasRef}></canvas>
            </div>

            <div className='characterContainer'>
                <div className="table-header">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Characteristic</th>
                                <th>Level</th>
                                <th>Rarity(%)</th>
                            </tr>
                        </thead>
                    </Table>
                </div>
                <div className='table-content'>
                    <Table striped bordered hover>
                        <tbody>
                            {selectedImages.map((item, index) => (
                                <tr key={index} className={`rarity-${item.rarity.toLowerCase()}`}>
                                    <td>{item.category}</td>
                                    <td className={item.rarity === "Legendary" ? "shake-text" : ""}>{item.rarity}</td>
                                    <td>{item.probability}%</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div >

            <div className="btn-container">
                <Button className="RedoBtn" onClick={() => initCharacterGenerator(canvasRef.current, setSelectedImages)}>
                    Generate
                </Button>
            </div>

            <div id="modal-link-container">
                <p className="modal-link">
                    <a href="https://github.com/ericksonl/RandomCharacterGenerator">GitHub Repo</a>
                </p>
            </div>

            <Footer />
        </>
    );
};

export default CharacterGen;
