import React from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import Portrait from '../icons/about/Portrait.jpg';
import '../styles/about.scss';

const About = () => {
    return (
        <>
            <NavBar />

            <div className="intro">
                <h1>About Me</h1>
            </div>
            <hr />
            <div className="about-container">
                <div className="image">
                    <img src={Portrait} id="aboutPic" alt=''></img>
                </div>
                <p className="text">
                    From a young age, I've been fascinated with patterns, always finding myself intrigued by the
                    connections in everyday life. Whether it was noticing the symmetry in a flower, or the
                    patterns in a song, I was obsessed with finding the underlying structure in the world around
                    me. The best way for me to express this curiosity was through art.

                    As I grew older, I began to see the connections between my love for art, technology, and
                    mathematics. They all shared a common theme - patterns and problem-solving. Mathematics, in
                    particular, felt like a natural fit for me. It was a discipline where I could apply my talent
                    for identifying patterns to solve complex problems. This realization fueled my decision to
                    pursue a Bachelor of Science degree in Mathematics and Computer Science.

                    In June 2023, I graduated from Southern Oregon University (SOU) with
                    a B.S in Mathematics and Computer Science. Notably, I received the Outstanding Mathematics/Computer
                    Science Co-Major award. During my time at SOU, I was active in several organizations, such as the
                    SOU Esports team and the Computer Science Club. From 2021 to 2022, I was the owner of a Discord server
                    with over 100 members to assist students with computer science courses. In June of 2022, my team of
                    three completed our capstone project, <a href="https://drive.google.com/file/d/1d8MkN8134weFe1lXJrHQ7E4GgyfyaG2A/edit">
                        Conversational Transcript
                    </a>.
                    It wasn't until my senior year of undergrad that I truly uncovered my passion for statistics and data analysis.
                    After taking two upper-division statistics courses with an incredible professor, Dr. Kim, I realized how
                    powerful data could be in uncovering insights and trends to solve real-world problems. This led me to 
                    pursue a Master's in Data Analytics at Oregon State University, where I continue to learn more about the
                    world of data analysis, prediction, and visualization. 

                    During my free time, I enjoy exploring new technologies, participating in hackathons, creating digital art,
                    exploring nature, and playing board games with my family.
                </p>

            </div >
            <Footer />
        </>
    );
}

export default About;

