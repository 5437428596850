import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/navBar.scss';

const NavBar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            <button className="mobile-burger" onClick={toggleMenu} aria-label="Toggle menu">
                <span></span>
            </button>
            <ul className={`menu-items ${isMenuOpen ? 'open' : ''}`}>
                <li><Link to="/" className="menu-item">Home</Link></li>
                <li><Link to="/about" className="menu-item">About</Link></li>
                <li><Link to="/art" className="menu-item">Art</Link></li>
                <li><Link to="/code" className="menu-item">Code</Link></li>
                <li><Link to="/dataAnalytics" className="menu-item">Data Analytics</Link></li>
                <li><Link to="/contact" className="menu-item">Contact</Link></li>
            </ul>
        </>
    );
}

export default NavBar;