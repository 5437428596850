import { rareChance } from "./RareCalc"

function fileHelper(num) {
    const fileNum = Math.floor(Math.random() * num) + 1
    return fileNum
}

function initCharacterGenerator(canvas, setSelectedImages) {
    if (!canvas) return

    const ctx = canvas.getContext('2d')
    canvas.width = 500
    canvas.height = 500

    function loadingScreen() {
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.fillStyle = '#19101d'
        ctx.fillRect(0, 0, canvas.width, canvas.height)

        ctx.fillStyle = 'White'
        ctx.font = '2.3rem nobel, sans-serif'
        ctx.textAlign = 'center'
        ctx.fillText('Loading...', canvas.width / 2, canvas.height / 2)
    }

    function loadAndDrawImages() {
        loadingScreen()

        const skin = rareChance('Skins')
        const shirt = rareChance('Shirts')
        const mouth = rareChance('Mouths')
        const eyes = rareChance('Eyes')
        const cone = rareChance('Cones')

        const imageSelections = [
            { category: "Skin", rarity: skin.rarity, probability: skin.probability, numItems: skin.numItems},
            { category: "Shirt", rarity: shirt.rarity, probability: shirt.probability, numItems: shirt.numItems},
            { category: "Mouth", rarity: mouth.rarity, probability: mouth.probability, numItems: mouth.numItems},
            { category: "Eyes", rarity: eyes.rarity, probability: eyes.probability, numItems: eyes.numItems},
            { category: "Cone", rarity: cone.rarity, probability: cone.probability, numItems: cone.numItems}
        ]

        console.log()

        const imageSources = [
            `${process.env.PUBLIC_URL}/BodyParts/Backgrounds/${fileHelper(14)}.png`,
            `${process.env.PUBLIC_URL}/BodyParts/${skin.rarity}/Skins/${fileHelper(skin.numItems)}.png`,
            `${process.env.PUBLIC_URL}/BodyParts/${shirt.rarity}/Shirts/${fileHelper(shirt.numItems)}.png`,
            `${process.env.PUBLIC_URL}/BodyParts/${mouth.rarity}/Mouths/${fileHelper(mouth.numItems)}.png`,
            `${process.env.PUBLIC_URL}/BodyParts/${eyes.rarity}/Eyes/${fileHelper(eyes.numItems)}.png`,
            `${process.env.PUBLIC_URL}/BodyParts/${cone.rarity}/Cones/${fileHelper(cone.numItems)}.png`,
        ]
        

        const images = []
        let imagesLoaded = 0

        if (setSelectedImages) {
            setSelectedImages(imageSelections);
        }

        function drawAllImages() {
            ctx.clearRect(0, 0, canvas.width, canvas.height)
            ctx.fillStyle = 'lightblue'
            ctx.fillRect(0, 0, canvas.width, canvas.height)

            images.forEach(img => ctx.drawImage(img, 0, 0, 500, 500))
        }

        imageSources.forEach((src, index) => {
            const img = new Image()
            img.src = src
            images[index] = img

            img.onload = () => {
                imagesLoaded++
                if (imagesLoaded === imageSources.length) {
                    drawAllImages()
                }
            }

            img.onerror = () => {
                console.error(`Failed to load image: ${src}`)
            }
        })
    }

    loadAndDrawImages()
}

export default initCharacterGenerator
