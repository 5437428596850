import React from 'react';
import ModalComponent from './ModalComponent';

class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true
        }
    }
    closeModal = () => {
        const modal = document.getElementById('modal');
        modal.classList.add('slide-up');
        modal.addEventListener('transitionend', () => {
            modal.remove();
        });
        this.setState({ isOpen: false });
    }

    render() {
        const { title, subHeader } = this.props.data;
        const data = this.props.data;
        const mappedData = data.images || data.links || data.videos
        const type = data.images ? 'image' : (data.links ? 'link' : 'video');
        return (
            <div id="modal" className='modal'>
                <div className='modal-content'>
                    <div className='btn-wrapper'>
                        <button variant='primary' className='close' id='close' onClick={this.closeModal}>X</button>
                    </div>
                    <div className="modal-intro">
                        <h1>{title}</h1>
                        <h3>{subHeader}</h3>
                        {this.props.data.disclaimer ? <p className="disclaimer">{this.props.data.disclaimer}</p> : ''}
                        {this.props.data.desc ? <p className="description">{this.props.data.desc}</p> : ''}
                        {this.props.data.questions ? (
                            <div className="question-list">
                                {this.props.data.questions
                                    .split(/(\d+\.\s+)/)
                                    .filter(Boolean)
                                    .reduce((acc, curr, idx) => {
                                        if (idx % 2 === 0) {
                                            acc.push(curr);
                                        } else {
                                            acc[acc.length - 1] += curr;
                                        }
                                        return acc;
                                    }, [])
                                    .map((q, index) => (
                                        <p key={index} className="question-item">{q.trim()}</p>
                                    ))}
                            </div>
                        ) : <hr />}

                        <div className={'modal-' + type + '-row'}>
                            {mappedData.map((item, index) => {
                                return (
                                    <ModalComponent
                                        key={index}
                                        type={type}
                                        title={item.title}
                                        url={item.url}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Modal;