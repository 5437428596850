import React from 'react'
import '../styles/fullscreen.scss'

class ModalComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            type: this.props.type,
            title: this.props.title,
            url: this.props.url
        }
    }

    render() {
        const { title, url, type } = this.state;
        if (type === 'image') {
            return (
                <div className="modal-column">
                    <div className="modal-container">
                        <img src={url} alt={title} className="modal-image"></img>
                    </div>
                </div>
            )
        } else if (type === 'video') {
            return (
                <div className="modal-video-container">
                    <iframe width="800" height="450" src={url} title={title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
            )
        } else {
            return (
                <div className="modal-link-container">
                    <p className='modal-link'>
                        <a href={url}>{this.props.title}</a>
                    </p>
                </div>
            )
        }
    }
}

export default ModalComponent