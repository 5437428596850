import React from 'react';
import { useLocation } from "react-router-dom";
import Footer from '../components/Footer';
import '../styles/about.scss';

const ReportPage = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const reportId = params.get("report") || "bmi-trends"; // Default to "bmi-trends" if no param
    return (
        <>
            <div className="report-container">
                <div className="iframe-wrapper">
                    <iframe
                        src={`/reports/${reportId}.html`}
                        title="R Markdown Report"
                        width="100%"
                        height="1000vh"
                        style={{
                            border: "none",
                            overflow: "hidden",
                            transform: "scale(0.95)", // Zoom out to 90%
                            transformOrigin: "top center", // Keeps it centered
                        }}
                    />
                </div>
            </div>

            <Footer />
            
        </>
    );
}

export default ReportPage;

