export function rareChance(category) {
    let rarity = Math.floor(Math.random() * 100) + 1;
    let rarityLevel, totalItems, rarityProb;

    const itemCounts = {
        Skins: { Common: 3, Uncommon: 5, Rare: 6, Legendary: 6 },
        Shirts: { Common: 4, Uncommon: 3, Rare: 4, Legendary: 4 },
        Mouths: { Common: 4, Uncommon: 4, Rare: 4, Legendary: 3 },
        Eyes: { Common: 3, Uncommon: 3, Rare: 3, Legendary: 3 },
        Cones: { Common: 3, Uncommon: 2, Rare: 3, Legendary: 3 }
    };

    if (rarity <= 50) {
        rarityLevel = "Common";
        rarityProb = 0.5
    } else if (rarity <= 80) {
        rarityLevel = "Uncommon";
        rarityProb = 0.30
    } else if (rarity <= 95) {
        rarityLevel = "Rare";
        rarityProb = 0.15
    } else {
        rarityLevel = "Legendary";
        rarityProb = 0.05
    }

    totalItems = itemCounts[category][rarityLevel];

    let probability = ((rarityProb * (1 / totalItems)) * 100).toFixed(2)

    return { rarity: rarityLevel, probability: probability, numItems: totalItems };
}
