import React from 'react'
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import Email from '../icons/contact/email.png';
import Github from '../icons/contact/GitHub-Mark-Light-120px-plus.png';
import LinkedIn from '../icons/contact/In-White-72.png';
import '../styles/contact.scss';

const Contact = () => {
    return (
        <>
            <NavBar />
            <div className="header">

                <div className="intro">
                    <h1 className="dh1">Contact Me</h1>
                </div>
                <hr />

                <div className="centerC">
                    <div className="containerC">
                        <a link className="contact-link" href="https://www.linkedin.com/in/liam-erickson/">
                            <div className="circle"><img src={LinkedIn} className="contact-img" alt=''></img></div>
                        </a>
                        <h3>LinkedIn</h3>
                        <a link className="contact-link" href="https://www.linkedin.com/in/liam-erickson/">/liam-erickson</a>

                    </div>
                    <div className="containerC">
                        <a link className="contact-link" href="https://github.com/ericksonl">
                            <div className="circle"><img src={Github} className="contact-img" alt=''></img></div>
                        </a>
                        <h3>GitHub</h3>
                        <a link className="contact-link" href="https://github.com/ericksonl">/ericksonl</a>
                    </div>
                    <div className="containerC">
                        <a className="contact-link" href="mailto:liamedev@gmail.com">
                            <div className="circle"><img src={Email} className="contact-img" alt=''></img></div>
                        </a>
                        <h3>Email</h3>
                        <a className="contact-link" href="mailto:liamedev@gmail.com">liamedev@gmail.com</a>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Contact;

