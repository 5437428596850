const data = [{
    art: {
        title: "Art",
        desc: "I use art as a form of expression, a way to capture my thoughts and feelings. Although I mainly work with digital art, I enjoy experimenting with other media like ceramics, painting, charcoal, animation, and more.",
        items: [
            {
                id: "hackathon",
                title: "Hackathon T-Shirt Designs",
                subHeader: "Created by Liam Erickson",
                desc: "For Southern Oregon University's annual Hackathon, I designed the event's t-shirts. I was first asked to work on this project in 2022 and was excited to do it again for 2023. Each year's design was inspired by that year's event theme. Created using Procreate and Adobe Illustrator, the shirts are a fun way to commemorate the event and provide a tangible memory for participants. I enjoy the challenge of creating something that resonates with the participants and sponsors and captures the spirit of the event.",
                url: require("../icons/art/hackathon.png"),
                images: [
                    {
                        title: "Hackathon 2023",
                        url: require("../images/hackathon/Front2023.png")
                    },
                    {
                        title: "Hackathon 2023",
                        url: require("../images/hackathon/Back2023.png")
                    },
                    {
                        title: "Hackathon 2022",
                        url: require("../images/hackathon/Front2022.png")
                    },
                    {
                        title: "Hackathon 2022",
                        url: require("../images/hackathon/Back2022.png")
                    }
                ]
            },
            {
                id: "digital-art",
                title: "Digital Art",
                subHeader: "Created by Liam Erickson",
                desc: "Discovering digital art opened up a whole new world of creativity for me. About 8 years ago, I got my hands on my first iPad, and I've been immersed in digital art ever since. Creators like Meatcanyon, Mama_Tox, LordSpew, and KetchupJonMustard fuel my inspiration, showing me what's possible and motivating me to push myself, learn new techniques, and continuously refine my craft.",
                url: require("../icons/art/digital-art.png"),
                images: [
                    {
                        title: "Voodoo",
                        url: require("../images/digital/Voodoo.png")
                    },
                    {
                        title: "Shrimp",
                        url: require("../images/digital/Shrimp.png")
                    },
                    {
                        title: "Bear & Bull",
                        url: require("../images/digital/Bull.png")
                    },
                    {
                        title: "Samhain",
                        url: require("../images/digital/Samhain.png")
                    },
                    {
                        title: "Ice Cream Man",
                        url: require("../images/digital/IceCreamMan.png")
                    },
                    {
                        title: "Nebula",
                        url: require("../images/digital/Nebula.png")
                    },
                    {
                        title: "Sleep Paralysis",
                        url: require("../images/digital/SleepParalysis.png")
                    },
                    {
                        title: "Tele-Bubby",
                        url: require("../images/digital/TeleBubby.png")
                    },
                    {
                        title: "Mind Bender",
                        url: require("../images/digital/TheMindBender.png")
                    },
                    {
                        title: "Miley the Smiley",
                        url: require("../images/digital/MileyTheSmiley.png")
                    }
                ]
            },
            {
                id: "parodies",
                title: "Parodies",
                subHeader: "Created by Liam Erickson",
                desc: "Crafting parodies of various characters has long been a source of joy for me. Influenced by creators like Meatcanyon, Mama_Tox, LordSpew, and KetchupJonMustard, I take inspiration from these artists to reimagine beloved childhood cartoons in my unique style, offering a fresh perspective on familiar worlds. Among my creations are interpretations of Adventure Time's Jake and Finn, Dandelion from the Witcher series, and Kuzco from The Emperor's New Groove.",
                url: require("../icons/art/parodies.png"),
                images: [
                    {
                        title: "Billy",
                        url: require("../images/parodies/Billy.png")
                    },
                    {
                        title: "Dandelion",
                        url: require("../images/parodies/Dandelion.png")
                    },
                    {
                        title: "Freddy Krueger",
                        url: require("../images/parodies/FreddyKrueger.png")
                    },
                    {
                        title: "Geralt",
                        url: require("../images/parodies/Geralt.png")
                    },
                    {
                        title: "Ghost Face",
                        url: require("../images/parodies/Ghostface.png")
                    },
                    {
                        title: "Grimace",
                        url: require("../images/parodies/Grimace.png")
                    },
                    {
                        title: "I Like Turtles",
                        url: require("../images/parodies/I_Like_Turtles.png")
                    },
                    {
                        title: "Finn",
                        url: require("../images/parodies/Finn.png")
                    },
                    {
                        title: "Jake",
                        url: require("../images/parodies/Jake.png")
                    },
                    {
                        title: "Jeff the Killer",
                        url: require("../images/parodies/JeffTheKiller.png")
                    },
                    {
                        title: "Kuzko",
                        url: require("../images/parodies/Kuzko.png")
                    },
                    {
                        title: "Pringle",
                        url: require("../images/parodies/Pringle.png")
                    },
                    {
                        title: "Shudderwock",
                        url: require("../images/parodies/Shudderwock.png")
                    },
                ]
            },
            {
                id: "traditional-art",
                title: "Traditional Art",
                subHeader: "Created by Liam Erickson",
                desc: "Working with physical mediums is how I began my career as an artist. Beginning with a pencil and paper, I eventually experimented with other media such as charcoal, ceramics, painting and more. My most recent project was participating in a pumpkin carving contest for my apartment complex, where I won first place.",
                url: require("../icons/art/traditional.png"),
                images: [
                    {
                        title: "Pumpkin Carving Contest",
                        url: require("../images/traditional/Pumpkin.png")
                    },
                    {
                        title: "Xmas Theme PC",
                        url: require("../images/traditional/PC1.png")
                    },
                    {
                        title: "Xmax Theme PC",
                        url: require("../images/traditional/PC2.png")
                    },
                    {
                        title: "Candle",
                        url: require("../images/traditional/Candle.png")
                    },
                    {
                        title: "Mooee",
                        url: require("../images/traditional/Mooee.png")
                    },
                    {
                        title: "Clown Doll",
                        url: require("../images/traditional/Clown.png")
                    },
                    {
                        title: "Untitled",
                        url: require("../images/traditional/Untitled.png")
                    },
                    {
                        title: "Tear",
                        url: require("../images/traditional/Tear.png")
                    },
                    {
                        title: "Untitled/Lizard in Jar",
                        url: require("../images/traditional/Lizard.png")
                    }
                ]
            },
            {
                id: "whispered-shadows",
                title: "Whispered Shadows",
                subHeader: "A mental health awareness series by Liam Erickson",
                disclaimer: "TRIGGER WARNING: This artwork contains depictions related to self-harm and suicidal ideation. If you or someone you know is struggling, please reach out for support. You are not alone. In the United States, contact the Suicide and Crisis Lifeline at 988 for immediate help. Your well-being matters, and help is available.",
                desc: "Mental health awareness is something I am deeply passionate about. In this series, I express my ongoing battle with Major Depressive Disorder, a diagnosis I got in 2018, and my recent diagnosis of dysthymia in 2023. The visuals reflect the intense emotions tied to mental health struggles. Art is my way of coping and finding strength in the face of my daily challenges. The series is a representation of my battles, but also highlights how art helps me heal and grow.",
                url: require("../icons/art/whispered-shadows.png"),
                images: [
                    {
                        title: "Bleed",
                        url: require("../images/whispered-shadows/Bleed.png")
                    },
                    {
                        title: "Monster",
                        url: require("../images/whispered-shadows/Monster.png")
                    },
                    {
                        title: "Untitled",
                        url: require("../images/whispered-shadows/Untitled.png")
                    },
                    {
                        title: "Abandoned",
                        url: require("../images/whispered-shadows/Abandoned.png")
                    },
                    {
                        title: "Mindwash",
                        url: require("../images/whispered-shadows/Mindwash.png")
                    },
                    {
                        title: "It Hurts",
                        url: require("../images/whispered-shadows/ItHurts.png")
                    },
                    {
                        title: "Isolated",
                        url: require("../images/whispered-shadows/Isolated.png")
                    },
                    {
                        title: "Depression",
                        url: require("../images/whispered-shadows/Depression.png")
                    },
                    {
                        title: "Melting",
                        url: require("../images/whispered-shadows/Melting.png")
                    },
                    {
                        title: "Shadows",
                        url: require("../images/whispered-shadows/Shadows.png")
                    },
                    {
                        title: "Masking",
                        url: require("../images/whispered-shadows/Masking.png")
                    },
                    {
                        title: "Body Dysmorphia",
                        url: require("../images/whispered-shadows/BDD.png")
                    },
                    {
                        title: "Last Birthday",
                        url: require("../images/whispered-shadows/Birthday.png")
                    }
                ]
            },
            {
                id: "videos-animation",
                title: "Videos & Animation",
                subHeader: "Created by Liam Erickson",
                desc: "Creating animations using Adobe Animate, After Effects, and more recently, Blender, is a genuine passion of mine. There's something uniquely satisfying about seeing my art transform into dynamic, animated sequences. For me, animation is about bringing ideas to life in ways static art cannot. It's an ongoing journey of creativity and growth that I'm excited to keep exploring.",
                url: require("../icons/art/videos-animation.png"),
                videos: [
                    {
                        title: "BRC Testing",
                        url: "https://www.youtube.com/embed/dHX8AukvkPA?si=M_78HfJWF6naMs_9"
                    },
                    {
                        title: "The Seer Backrooms",
                        url: "https://www.youtube.com/embed/wg3fwlQgJiA?si=957Gr7qIjIC9L5Vp"
                    },
                    {
                        title: "Toss A Coin To Your Witcher (Chorus Animated)",
                        url: "https://www.youtube.com/embed/gUtJv2ih1uM?si=bttMS_4zgIybFbW0"
                    },
                    {
                        title: "Hearthstone Audio Animated",
                        url: "https://www.youtube.com/embed/UTzRlb2fhMc?si=20BncrB7r6Egz-vj"
                    },
                    {
                        title: "Bedtime",
                        url: "https://www.youtube.com/embed/a8rC6IwQZjg?si=DkHU1yw3x51QCeop"
                    }
                ]
            }
        ]
    },
    code: {
        title: "Code",
        desc: "I have experience coding in many languages such as Javascript, Java, R, GoLang, and Python. I love implementing my artwork with code as often as I can, which lead me to create staple project such as the Random Character Generator.",
        items: [
            {
                id: "daily-journal",
                title: "DailyJournal",
                subHeader: "A Discord Journaling Bot by Liam Erickson",
                desc: "DailyJournal is a Discord bot that brings the joy of journaling to your server. With DailyJournal, you can keep track of your thoughts, experiences, and reflections right within Discord. It provides a private and personal space for users to express themselves, set goals, and document their daily journey. DailyJournal features AES encryption, ensuring your data remains confidential and secure. Browse past entries, share selected ones, track mood with a built-in chart. Express, reflect, and document your personal journey within Discord.",
                url: require("../icons/code/daily-journal.png"),
                links: [
                    {
                        title: "GitHub Repo",
                        url: "https://github.com/ericksonl/DailyJournal"
                    }
                ]
            },
            {
                id: "shakespearean-translator",
                title: "Shakespearean Translator",
                subHeader: "A translation software created for SOU Hackathon 2023",
                desc: "2nd Place Winner in the Southern Oregon University Hackathon 2023. Shakespeare is well known for having introduced thousands of new words to the English vocabulary. Although many are used to this day, some have not been used since his time. When listening to the slang of Gen Z and Gen Alpha, I often feel like I'm reading a Shakespearean play. I can pick out a few words, but I leave feeling confused and angry. How do they expect generations who have seemingly invented their own language to read the great works of William Shakespeare? The answer is simple: Translate it to Gen-Z slang.",
                url: require("../icons/code/sp-translator.png"),
                links: [
                    {
                        title: "GitHub Repo",
                        url: "https://github.com/ericksonl/souhackathon2023"
                    },
                    {
                        title: "Live website",
                        url: "../SPtoGenZ/"
                    }
                ]
            },
            {
                id: "bird-watcher",
                title: "BirdWatcher",
                subHeader: "A Twitter monitor by Liam Erickson",
                desc: "In today's world, where importance is often measured by follows, keeping up with all those followers can be pretty difficult. To solve this problem, I designed BirdWatcher, a Twitter monitor. BirdWatcher tracks all your follows and provides updates on new and lost followers. Whether you want to see if the new guy down the street is following your ex (she probably left you for him), monitor your own followers, or just see what type of person follows Brad Pitt, BirdWatcher is the tool for you. Building this bot required the Twitter API, Discord API, and MongoDB for storing guild data.",
                url: require("../icons/code/bird-watcher.png"),
                links: [
                    {
                        title: "GitHub Repo",
                        url: "https://github.com/ericksonl/BirdWatcher"
                    }
                ]
            },
            {
                id: "conversational-transcript-2022",
                title: "Conversational Transcript",
                subHeader: "A transcription software created for SOU",
                disclaimer: "Note: As of 2024, this website has been deprecated and is no longer publicly accessible. However, the link to my group's presentation remains available.",
                desc: "Southern Oregon University is required to produce written transcripts for all meetings. Without the assistance of software, this can be a very tedious and expensive task. In 2020, the first version of Conversational Transcript was created. This version allowed users to upload audio recordings and have the software automatically generate transcriptions. In 2022, my team of three implemented in-app audio recording, a user-friendly software guide, and improved the software documentation. This project was created using Python, ReactJS, and Amazon Web Services. I primarily worked on ReactJS and implementing the in-app recording software.",
                url: require("../icons/code/ct-2022.png"),
                links: [
                    {
                        title: "Capstone presentation video",
                        url: "https://drive.google.com/file/d/1d8MkN8134weFe1lXJrHQ7E4GgyfyaG2A/edit"
                    }
                ]
            },
            {
                id: "ice-cream-character-generator",
                title: "Character Generator",
                subHeader: "A character generator by Liam Erickson",
                desc: "Ice Cream Character Generator is a way to integrate art into the world of programming. I was inspired by the increase in popularity of NFTs (such as Bored Ape Yacht Club). While examining some NFT collections, I noticed that each one had specific features it randomly inherited. I knew I could make something that automated this process. This program randomly generates an image from a collection of artworks. This collection consists of over 100 features drawn using Procreate and Photoshop. The generated images can have various traits based on a self-made probability scale while containing the same base features. I originally made this project using Java, then decided to make it available on my webpage and converted it to JavaScript.",
                url: require("../icons/code/rcg.png"),
                links: [
                    {
                        title: "GitHub Repo",
                        url: "https://github.com/ericksonl/RandomCharacterGenerator"
                    },
                    {
                        title: "Live preview",
                        url: "/code/characterGen"
                    }
                ]
            },
            {
                id: "8",
                title: "8",
                disclaimer: "Warning: This game is not mobile friendly.",
                subHeader: "A side-scroller game created for EMDA 203: Coding and Interactive Design",
                desc: "8 is a side-scroller horror game created by my team of four for EMDA 203. In this game, you take control of a character navigating the horrors of his house on his 8th birthday. The goal is simple: escape your house alive. For this project, I was the lead programmer. This meant implementing the artwork and sound, and creating user-friendly controls that worked well with the storyline. The majority of the game was written in JavaScript, with a bit of CSS and HTML.",
                url: require("../icons/code/8.png"),
                links: [
                    {
                        title: "GitHub Repo",
                        url: "https://github.com/ericksonl/8Game"
                    },
                    {
                        title: "Live website",
                        url: "../8Game/TitleScreen/"
                    }
                ]
            },
            {
                id: "laughing-stock",
                title: "Laughing Stock",
                subHeader: "Award-Winning Game created for HackOR 2021",
                desc: "Laughing Stock was created for the anual HackOR 2021 48-hour Hackathon coding event. After 48 hours of programming, drawing, laughing, crying, yelling, taco bell, and Wi-Fi outages, my team of 4 created this award winning game. Laughing Stock is a GUI based choose-your-own-adventure horror puzzle game. Inspired by our own personal interests (horror, aviation, old-school single-image games), this game won first place overall. Hand drawn art and a 'riveting' storyline make you want to continue, but winning is just out of reach.",
                url: require("../icons/code/laughing-stock.png"),
                links: [
                    {
                        title: "GitHub Repo",
                        url: "https://github.com/peterj812/HackORQuartusPrimates"
                    },
                    {
                        title: "Devpost Submission",
                        url: "https://devpost.com/software/quartus-primates-project"
                    }
                ]
            }
        ]
    },
    da: {
        title: "Data Analytics",
        desc: "I am currently pursuing my Master's in Data Analytics at Oregon State University, combining my passion for data and mathematics. I love uncovering patterns and trends that have a real world impact. My goal is to build a career in Data Science, where I can apply my skills to meaningful challenges and continue exploring the power of data.",
        items: [
            {
                id: "bmi-trends",
                title: "Youth BMI Trends",
                subHeader: "A data analysis project by Liam Erickson",
                desc: "The Youth Risk Behavior Surveillance System (YRBSS) collected data on body mass index (BMI) from high school students in the United States in 2007 and 2017. This analysis explores trends in BMI, physical activity, and sleep across the two datasets. In Task 1, we conduct a simulation study to examine how sample size affects the sampling distribution of key statistics such as mean, 25th percentile, and minimum BMI. In Task 2, we address the following questions of interest:", 
                questions : "1. How has the BMI of high school students changed between 2007 and 2017? Are high schoolers becoming more overweight? 2. In 2017, are 12th graders more or less likely than 9th graders to be “physically active at least 60 minutes per day on 5 or more days”? 3. How much sleep do high-schoolers get?",
                url: require("../icons/da/bmi.png"),
                links: [
                    {
                        title: "Read more...",
                        url: "/reports?report=bmi-trends"
                    },
                ]
            },
            {
                id: "cascade-snowpack-trends",
                title: "Cascade Snowpack Trends",
                subHeader: "A data analysis project by Liam Erickson",
                desc: "From 1992 to 2022, data on snow water equivalent (SWE) measurements were collected by the USDA Natural Resources Conservation Service through the National Water and Climate Center Report Generator. This dataset includes daily SWE observations at two SNOTEL locations in the Cascade Range: Hogg Pass and Santiam Pass. SWE data reflects the amount of water contained in the snowpack, measured in inches, providing insights into seasonal and yearly snowpack dynamics. Each record in the dataset corresponds to a daily SWE measurement, with the water year beginning on October 1st and ending on September 30th of the following year. This analysis aims to address the following questions:",
                questions: "1. Is there any change in the yearly peak amount of snow over the period 1992–2022? 2. In the 2018 water year, how did the daily values of snow water equivalent compare between the two stations?",
                url: require("../icons/da/snow.png"),
                links: [
                    {
                        title: "Read more...",
                        url: "/reports?report=cascade-snowpack-trends"
                    },
                ]
            },
            {
                id: "sleep-analysis",
                title: "Sleep Analysis",
                subHeader: "A data analysis project by Liam Erickson",
                desc: "Since July 2016, I have been collecting my sleep data using the SleepCycle app, available on both iOS and Android. This app uses the microphone in your phone to track various aspects of your sleep (which you can read about in SleepCycle's Privacy Policy). Among the multitude of data points captured by the app, I narrowed my focus to 12 specific variables. In particular, I was interested in Sleep Quality, exploring the influencing factors, and attempting to develop a predictive model for future sleep quality. This project predicts my Sleep Quality using a linear regression model based on predictors such as time in bed, time asleep, temperature, alarm, and steps. During this project, I discovered in addition to not walking nearly enough on average, my sleep quality is... not great. On average, I spend 6 hours 31 minutes and 58 seconds in bed. 5 hours 52 minutes and 4 seconds of that time I spend asleep, and have an average sleep quality of 67.57%. After identifying the top predictors and building my prediction model, I was able to use this model to predict Sleep Quality. According to my findings, an 'average person' (with respect to the five predictors in the regression model) will have a sleep quality of 67.57%. To read more about this project, please see the README.md of my GitHub repo, linked below.",
                questions: ' ',
                url: require("../icons/da/sleep-data.png"),
                links: [
                    {
                        title: "Read more...",
                        url: "https://github.com/ericksonl/SleepData"
                    },
                ]
            }
        ]
    }
}]

export default data;