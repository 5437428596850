import React from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

const Chris = () => {
    return (
        <>
            <NavBar />

            <div className="intro">
                <h1>Chris</h1>
            </div>
            <hr />

            <Footer />
        </>
    );
}

export default Chris;

